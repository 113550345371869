<script lang="ts" setup>

</script>
<template>
	<div class="relative w-full" >
		<img src="../assets/header.jpg" width="3000" height="2996" class="w-full" />
		<div class="grid grid-cols-3 mx-auto px-6 lg:px-10 min-h-screen absolute left-0 right-0 top-0">
			<div class="flex justify-center items-start flex-col">
				<h1 class="font-bold font-visby text-4xl">Understand Your Body.<br>Know Your Cycle.</h1>
				<p class="mt-5 mb-10 text-xl">Perioden und Zykus Tracker App mit Schwangerschaft Modus. Lebe im Einklang mit deinem Zyklus und nutze deine weibliche Energie.</p>
				<div class="flex flex-row gap-4">
					<NuxtLink to="/subscribe" class="bg-black rounded-full px-6 py-3 font-bold text-white font-visby">Beta Sign Up</NuxtLink>
					<!-- <NuxtLink to="/video" class="border border-black rounded-full px-6 py-3 font-bold font-visby">Watch the video</NuxtLink> -->
				</div>
			</div>

			<div class="flex justify-center items-center -translate-y-10 ">
				<SunLogo color="#ffffff" :animateColor="true" :diameter1="180" :diameter2="300" :magnet-radius="70" :line-width="14" />
			</div>
		</div>
	
	</div>

</template>