<script lang="ts" setup>
const year = new Date().getFullYear()
const copyRight = ref<string>(`© 2022 - ${year} Offlinefirst`)
</script>
<template>
	<div class="relative mx-auto px-6 lg:px-10 mb-10 mt-10">
		<AppIcon class="z-50 w-20 h-20 mb-8" />
		<p class="font-visby font-semibold ml-1 text-2xl">Amy</p>
		<p class="font-visby text-6xl font-bold">Period<br>and pregnancy<br>tracker App</p>
		<div class="grid grid-cols-12">
			<div class="-translate-x-1 flex flex-row gap-x-2 mt-10 col-span-8">
				<IconInstagram />
				<IconTwitter />
				<IconPlaystore />
				<IconApple />
			</div>


			<!-- <div class="col-span-4 flex flex-row items-center gap-2">
				<QRCode class="w-48 -translate-x-4" />
				<p class="font-visby text-sm max-w-xs"><b>Download Amy app</b><br>Scan the QR code to download the app from the App Store or Google Play Store</p>
			</div> -->


		</div>
		<div class="grid grid-cols-12 mt-20 ">
			<p class="text-xs col-span-8">{{copyRight}}</p>
			<div class="flex flex-row gap-8 text-sm font-visby font-bold col-span-4">
				<NuxtLink to="/imprint" >Imprint</NuxtLink>
				<NuxtLink to="/terms" >Terms & Condition</NuxtLink>
				<NuxtLink to="/privacy" >Privacy</NuxtLink>
			</div>
		</div>
	</div>
</template>