<script lang="ts" setup>
const { locale, locales} = useI18n()
const switchLocalePath = useSwitchLocalePath()
const availableLocales = computed(() => {
  return (locales.value as {code: string, name: string}[]).filter(i => i.code !== locale.value)
})
</script>
<template>
	<div class="font-visby font-semibold w-full px-10 pt-6 absolute z-50 top-0">

	<nav class="flex flex-row items-center flex-grow ">
		<AppIcon class="z-50 w-14 h-14 mr-4" /><p class="text-2xl">Amy</p>	
		<span class="ml-auto" v-for="locale in availableLocales" :key="locale.code">	
			<NuxtLink :to="switchLocalePath(locale.code)">{{ locale.name }}</NuxtLink>
		</span>
	</nav>
	</div>
</template>