
<script lang="ts" setup>

definePageMeta({
  title: 'pages.home.title',
})

useHead({
  meta: [{ property: "og:title", content: "Amy" }]
})


</script>
<template>

	<Header />
	<Nav />
	<Footer />
</template>